import * as React from "react";
import "./interne.css";
import Layout from "../layouts/layout";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import parse from 'html-react-parser';
import favicon from "../images/favico.ico";
import { StaticImage } from "gatsby-plugin-image"





const ChurigPage = (props) => {
    const { content } = props.pageContext;
    const { title } = props.pageContext;
    const { titleMeta } = props.pageContext;
    const { metaDesc } = props.pageContext;
    const { childs } = props.pageContext;
    const { uri } = props.pageContext;
    const { faq } = props.pageContext

    console.log("faaaaq", faq)
    const children = [];

    if (faq) {
        if (faq.question1 != null) {
            children.push({ question: faq.question1, answer: faq.reponse1 })
        }
        if (faq.question2 != null) {
            children.push({ question: faq.question2, answer: faq.reponse2 })
        }
        if (faq.question3 != null) {
            children.push({ question: faq.question3, answer: faq.reponse3 })
        }
        if (faq.question4 != null) {
            children.push({ question: faq.question4, answer: faq.reponse4 })
        }
        if (faq.question5 != null) {
            children.push({ question: faq.question5, answer: faq.reponse5 })
        }
        if (faq.question6 != null) {
            children.push({ question: faq.question6, answer: faq.reponse6 })
        }
        if (faq.question7 != null) {
            children.push({ question: faq.question7, answer: faq.reponse7 })
        }
        if (faq.question8 != null) {
            children.push({ question: faq.question8, answer: faq.reponse8 })
        }
        if (faq.question9 != null) {
            children.push({ question: faq.question9, answer: faq.reponse9 })
        }
        if (faq.question10 != null) {
            children.push({ question: faq.question10, answer: faq.reponse10 })
        }
    }
    let style = "container-fluid service2 pt-5 pb-5";

    childs.map((child, i) => {
    
        if (child === "chirurgie-obesite-tunisie") {
            style = "container-fluid service2 pt-5 pb-5";
        }else if(child === "chirurgie-silhouette-tunisie"){
            style = "container-fluid service2 pt-5 pb-5";

        }
        else if(child === "acide-hyaluronique"){
            style = "container-fluid service2 pt-5 pb-5";

        }
        // Vous pouvez utiliser la variable `style` dans d'autres traitements ici
    });
    const path = typeof window !== "undefined" 
    ? window.location.pathname.split("/").filter(Boolean).pop() 
    : "";
      console.log("paath",path); 
    return (
        <Layout>

            <Helmet htmlAttributes={{
                lang: 'fr-FR',
            }}>
                <link rel="icon" type="image/x-icon" href={favicon} />
                <title>{titleMeta}</title>
                <meta name="description" content={metaDesc} />
                <meta name="robots" content="index, follow" />
                <link data-react-helmet="true" rel="canonical" href={"https://www.medica-tour.fr" + uri}></link>
                <script type="application/ld+json">
                    {JSON.stringify(children)}</script>
                <meta name="msvalidate.01" content="BF6180D2B9843FD051D8456A095B9C38" />
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-TSKFDDEV9E"></script>
                <script>
                    {`
        window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-TSKFDDEV9E');
      `}
                </script>

                <script>
                    {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-MC7KJQM');`}
                </script>
                <script>
                    {`
                            !function(f,b,e,v,n,t,s)
                            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                            n.queue=[];t=b.createElement(e);t.async=!0;
                            t.src=v;s=b.getElementsByTagName(e)[0];
                            s.parentNode.insertBefore(t,s)}(window, document,'script',
                            'https://connect.facebook.net/en_US/fbevents.js');
                            fbq('init', '8919602118131306');
                            fbq('track', 'PageView');
                        `}
                </script>
            </Helmet>
            <noscript>
                <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MC7KJQM"
                    height="0" width="0" style={{ display: "none", visibility: "hidden" }}></iframe>
                <img height="1" width="1" style={{ display: "none" }}
                    src="https://www.facebook.com/tr?id=8919602118131306&ev=PageView&noscript=1" />
            </noscript>
            <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MC7KJQM"
                height="0" width="0" style={{ display: "none", visibility: "hidden" }} ></iframe></noscript>
  {/* <div className="container-fluid service1 pt-5 pb-5">
                <div className="container intern-page-style">
                    <h1 className="service-title1" >
                        {title}
                    </h1>
                </div>
            </div> */}
{path === "chirurgie-obesite-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/bariatrique.webp" 
            className="img-fluid service-image" 
            alt="Chirurgie bariatrique en Tunisie : Des Interventions Efficaces contre l’Obésité" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
{path === "chirurgie-dentaires-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/chirurgie-dentaire-tunisie.webp" 
            className="img-fluid service-image" 
            alt="Chirurgie Dentaire en Tunisie | Implants et Soins Esthétiques" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
     {path === "chirurgie-silhouette-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/chirurgie-silhouette.webp" 
            className="img-fluid service-image" 
            alt="Chirurgie Silhouette en Tunisie | Remodelage et Raffermissement" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}    
   {path === "medecine-esthetique" && (
    <div className="image-container">
        <StaticImage 
            src="../images/med-esthetique.webp" 
            className="img-fluid service-image" 
            alt="Médecine esthétique en Tunisie - Botox, acide hyaluronique et lasers" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)} 
   {path === "chirurgie-visage" && (
    <div className="image-container">
        <StaticImage 
            src="../images/med-de-visage.webp" 
            className="img-fluid service-image" 
            alt="Chirurgie du visage en Tunisie - Lifting, rhinoplastie, blépharoplastie" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)} 
       {path === "chirurgie-mammaire" && (
    <div className="image-container">
        <StaticImage 
            src="../images/chirurgie-sein.webp" 
            className="img-fluid service-image" 
            alt="Chirurgie mammaire en Tunisie - Augmentation, réduction et reconstruction des seins" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}        
    {path === "chirurgie-intimite-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/chirurgie-de-l_intimité.webp" 
            className="img-fluid service-image" 
            alt="Chirurgie intime en Tunisie - Correction et rajeunissement des parties intimes" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)} 
    {path === "traitement-infertilite-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/PMA.webp" 
            className="img-fluid service-image" 
            alt="Traitement de l'infertilité en Tunisie - PMA, FIV et ICSI" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}   
    {path === "greffe-cheveux-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/cheveux.webp" 
            className="img-fluid service-image" 
            alt="Greffe de cheveux en Tunisie - Implant capillaire FUE et DHI" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)} 
    {path === "chirurgie-obesite-tunisie/sleeve-gastrectomie/" && (
    <div className="image-container">
        <StaticImage 
            src="../images/sleeve.webp" 
            className="img-fluid service-image" 
            alt="Greffe de cheveux en Tunisie - Implant capillaire FUE et DHI" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)} 
   {path === "acide-hyaluronique" && (
    <div className="image-container">
        <StaticImage 
            src="../images/acide_hyaluronique.webp" 
            className="img-fluid service-image" 
            alt="Injections acide hyaluronique en Tunisie - Rajeunissement et hydratation" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "la-toxine-botulique-ou-bot-ox" && (
    <div className="image-container">
        <StaticImage 
            src="../images/toxine-botulique.webp" 
            className="img-fluid service-image" 
            alt="Botox en Tunisie - Injections sécurisées de toxine botulique" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "traitement-laser-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/traitement-laser.webp" 
            className="img-fluid service-image" 
            alt="Traitement laser en Tunisie - Épilation et soins cutanés" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "le-mesobotox-ou-microbotox" && (
    <div className="image-container">
        <StaticImage 
            src="../images/mesobtox.webp" 
            className="img-fluid service-image" 
            alt="Microbotox en Tunisie - Hydratation et raffermissement de la peau" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "prp" && (
    <div className="image-container">
        <StaticImage 
            src="../images/prp-tunisie.webp" 
            className="img-fluid service-image" 
            alt="PRP en Tunisie - Rajeunissement naturel par injection de plasma" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "rhinoplastie-medicale-en-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/rhinoplastie-medicale.webp" 
            className="img-fluid service-image" 
            alt="Rhinoplastie médicale en Tunisie - Remodelage du nez sans chirurgie" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "techniques-amincissement" && (
    <div className="image-container">
        <StaticImage 
            src="../images/Techniques-d’Amincissement.webp" 
            className="img-fluid service-image" 
            alt="Techniques d’amincissement en Tunisie - Cryolipolyse, radiofréquence, ultrasons" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "fils-tenseurs" && (
    <div className="image-container">
        <StaticImage 
            src="../images/fils-tenseurs-en-tunisie.webp" 
            className="img-fluid service-image" 
            alt="Fils tenseurs en Tunisie - Lifting du visage sans chirurgie" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "mesotherapie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/mésothérapie.webp" 
            className="img-fluid service-image" 
            alt="Mésothérapie en Tunisie - Hydratation et anti-âge sans chirurgie" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "chirurgiens-esthetiques-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/mésothérapie.webp" 
            className="img-fluid service-image" 
            alt="Mésothérapie en Tunisie - Hydratation et anti-âge sans chirurgie" 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "hebergement-chirurgie-esthetique-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/hotel-tunisie.webp" 
            className="img-fluid service-image" 
            alt="Hôtel en Tunisie - Hébergement 5 étoiles pour un séjour médical confortable avec Medica Tour." 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "clinique" && (
    <div className="image-container">
        <StaticImage 
            src="../images/clinic.webp" 
            className="img-fluid service-image" 
            alt="Clinique en Tunisie - Établissement médical moderne et équipé pour des interventions sécurisées avec Medica Tour." 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
   {path === "sejour-medical-en-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/sejour.webp" 
            className="img-fluid service-image" 
            alt="Séjour médical en Tunisie - Prise en charge complète avec hébergement et assistance personnalisée par Medica Tour." 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}
            <div className="container mt-4 mb-4">
                <div className="row">
                    {

                    }
                    {childs && childs.map((child, i) => {
                        if (child.title == "Ballon gastrique en Tunisie : Une Solution Simple pour Perdre du Poids") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/ballon-gastriqu.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }

                        if (child.title == "Abdominoplastie en Tunisie : Retrouver Confiance en Votre Corps") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/abdo-1.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Mini Abdominoplastie en Tunisie : Une Solution Ciblée pour un Ventre Parfait") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/min-abdo-1.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Body Lift en Tunisie : Redéfinissez Votre Silhouette en Toute Confiance") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/body-lift-1.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Lifting des Bras en Tunisie : Retrouver des Bras Fins et Raffermis") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/lifting-bras-women-1.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Lifting des Cuisses en Tunisie : Des Jambes Raffermies et Élégantes") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/lifting-cuisse-2.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Augmentation des mollets Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/aug-mollet-1.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Liposuccion en Tunisie : Affinez Votre Silhouette avec Medica Tour") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/Liposuccion-1.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Augmentation des Fesses en Tunisie : Offrez-vous un Fessier Harmonieux et Rebondi") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/aug-fesses-1.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Lipofilling Fessier en Tunisie : Offrez-vous un Galbe Naturel et Élégant") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/lipolling-fesse-1.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "BBL en Tunisie : Le Lifting Brésilien pour un Fessier Harmonieux") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/bbl-1.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Lifting des Fesses en Tunisie : Des Résultats Naturels et Durables") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/by-pass.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Augmentation Mammaire en Tunisie : Offrez-vous la Poitrine de Vos Rêves") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/augmentation-mammaire.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Lifting Mammaire en Tunisie : Redonnez Jeunesse et Fermeté à Votre Poitrine") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/lifting-seins.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Lipofilling Mammaire en Tunisie : Une Poitrine Naturelle et Raffinée") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/lipofilling.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Réduction Mammaire en Tunisie : Offrez vous Confort et Proportion") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/reduction.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Bypass gastrique en Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/by-pass.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Anneau gastrique en Tunisie : Une Solution Bariatrique Peu Invasive") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/anneau.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "sleeve gastrique en Tunisie : Une Chirurgie Efficace contre l’Obésité Sévère") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/sleeve-gas1.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Implant Dentaire en Tunisie : Retrouver Votre Sourire avec Confiance") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/implant.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Gynécomastie en Tunisie : Une Solution Discrète pour Retrouver Confiance") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/Gynecomastie.jpg" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Blépharoplastie en Tunisie : Rajeunissez Votre Regard avec Medica Tour") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/Blepharoplastie-1.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Genioplastie en Tunisie : Sublimez Votre Profil avec Medica Tour") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/Genioplastie.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Lifting Visage en Tunisie : Offrez-vous un Visage Jeune et Rajeuni") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/lifiting.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Mini Lifting Visage en Tunisie : Une Solution Discrète pour Rajeunir") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/mini-lifting.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Otoplastie en Tunisie : Redonnez Harmonie à Vos Oreilles avec Medica Tour") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/Otoplastie-.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Rhinoplastie en Tunisie : Remodeler et Harmoniser Votre Nez") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/Rhinoplastie-1.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Lipofilling Visage en Tunisie : Sublimez Votre Visage avec Medica Tour") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/lipofilling-visage-en-tunisie.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Canthopexie en Tunisie : Offrez-vous un Regard Sublimé et Élégant") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/Canthopexie.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Facettes dentaires en Tunisie : Améliorez Votre Sourire à Prix Abordable") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/facette.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Blanchiment Dentaire en Tunisie : Offrez-vous un Sourire Radieux à Petit Prix") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/blanch.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Gingivectomie Tunisie : Harmonisez Votre Sourire en Toute Confiance") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/gingivectomie.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Couronne dentaire en Tunisie :  Redonnez Esthétique et Fonction à Votre Sourire") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/couronne-dentaire.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Biopsie testiculaire en Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/testiculaire.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Fécondation in vitro en Tunisie (FIV)") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/vitro.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Insémination artificielle Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/artificielle.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Greffe de Poils et Cheveux en Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/chev.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )



                        }
                        if (child.title == "Greffe de Barbe en Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/barbre.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Greffe et Implant de Sourcils en Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/sourcil.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Liposuccion Vaser en Tunisie : Une Solution de Précision pour Sublimer Votre Silhouette") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/lipo-vaser-1.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        if (child.title == "Acide Hyaluronique") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/acide-hylaronique.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "La Toxine Botulique ou Bot-ox") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/botox.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Traitement Laser") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/trait-laser.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Mesobotox ou Microbotox") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/mesobotox.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "PRP Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/PRP.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Rhinoplastie Médicale en Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/rhinoplastie.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Les Techniques d’Amincissement") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/amincissement.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Fils Tenseurs") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/fils-tenseurs.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Mésothérapie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/mesotherapie.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }


                        if (child.title == "Nymphoplastie en Tunisie : Réduction des lèvres vaginales") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/nymphoplastie.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Vaginoplastie Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/vaginoplastie.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Hyménoplastie en Tunisie : Restauration de l&rsquo;hymen") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/hymenoplastie.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Penoplastie en Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/penoplastie.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Prothèse pénienne gonflable") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/prothese-penienne-gonflable.webp" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (child.title == "Implant Pénien Semi-Rigide en Tunisie") {
                            return (

                                <div className="col-sm-12 col-md-3 mt-4 mb-4">
                                    <div className="card h-100" >

                                        <Link to={child.uri} className="link-interv">
                                            <StaticImage src="../images/implant-semi-rigidexx.png" className="card-img-top img-fluid" alt={child.title} />
                                        </Link>
                                        <div className="card-body card-body-intervention   w-100">
                                            <Link to={child.uri} className="link-interv">
                                                <span className="text-center card-body-title ">{child.title}</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    
                    })



                    }

                </div>
            </div>
        

            <div className="container-fluid  pb-5 blog-single">
                <div className="container ">
                    <div className="row">

                        {content && parse(content)}
                        {(() => {
                            const options = [];
                            if (faq.question1 != null) {
                                options.push(<h2>FAQ</h2>)
                            }
                            const options1 = [];

                            if (faq.question1 != null) {
                                options1.push(
                                    <div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                        <h3 itemprop="name">  {faq.question1}</h3>
                                        <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                            <div itemprop="text">
                                                {faq.reponse1}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            if (faq.question2 != null) {

                                options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                    <h3 itemprop="name">  {faq.question2}</h3>
                                    <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <div itemprop="text">
                                            {faq.reponse2}
                                        </div>
                                    </div>
                                </div>)
                            }
                            if (faq.question3 != null) {
                                options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                    <h3 itemprop="name">  {faq.question3}</h3>
                                    <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <div itemprop="text">
                                            {faq.reponse3}
                                        </div>
                                    </div>
                                </div>)
                            }
                            if (faq.question4 != null) {
                                options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                    <h3 itemprop="name">  {faq.question4}</h3>
                                    <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <div itemprop="text">
                                            {faq.reponse4}
                                        </div>
                                    </div>
                                </div>)
                            }
                            if (faq.question5 != null) {

                                options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                    <h3 itemprop="name">  {faq.question5}</h3>
                                    <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <div itemprop="text">
                                            {faq.reponse5}
                                        </div>
                                    </div>
                                </div>)
                            }
                            if (faq.question6 != null) {
                                options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                    <h3 itemprop="name">  {faq.question6}</h3>
                                    <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <div itemprop="text">
                                            {faq.reponse6}
                                        </div>
                                    </div>
                                </div>)
                            }
                            if (faq.question7 != null) {
                                options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                    <h3 itemprop="name">  {faq.question7}</h3>
                                    <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <div itemprop="text">
                                            {faq.reponse7}
                                        </div>
                                    </div>
                                </div>)
                            }
                            if (faq.question8 != null) {
                                options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                    <h3 itemprop="name">  {faq.question8}</h3>
                                    <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <div itemprop="text">
                                            {faq.reponse8}
                                        </div>
                                    </div>
                                </div>)
                            }
                            if (faq.question9 != null) {
                                options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                    <h3 itemprop="name">  {faq.question9}</h3>
                                    <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <div itemprop="text">
                                            {faq.reponse9}
                                        </div>
                                    </div>
                                </div>)
                            }
                            if (faq.question10 != null) {
                                options1.push(<div itemScope itemprop="mainEntity" itemType="https://schema.org/Question">
                                    <h3 itemprop="name">  {faq.question10}</h3>
                                    <div itemScope itemprop="acceptedAnswer" itemType="https://schema.org/Answer">
                                        <div itemprop="text">
                                            {faq.reponse10}
                                        </div>
                                    </div>
                                </div>)
                            }
                            return [options, options1];
                        })()}
                    </div>
                </div>
            </div>

        </Layout>
    )

}

export default ChurigPage
